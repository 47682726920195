// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-confirmation-page-confirmation-page-js": () => import("./../../../src/pages/ConfirmationPage/ConfirmationPage.js" /* webpackChunkName: "component---src-pages-confirmation-page-confirmation-page-js" */),
  "component---src-pages-confirmation-page-index-js": () => import("./../../../src/pages/ConfirmationPage/index.js" /* webpackChunkName: "component---src-pages-confirmation-page-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-not-found-index-js": () => import("./../../../src/pages/NotFound/index.js" /* webpackChunkName: "component---src-pages-not-found-index-js" */),
  "component---src-pages-not-found-not-found-js": () => import("./../../../src/pages/NotFound/NotFound.js" /* webpackChunkName: "component---src-pages-not-found-not-found-js" */),
  "component---src-pages-payment-request-index-js": () => import("./../../../src/pages/PaymentRequest/index.js" /* webpackChunkName: "component---src-pages-payment-request-index-js" */),
  "component---src-pages-payment-request-payment-request-js": () => import("./../../../src/pages/PaymentRequest/PaymentRequest.js" /* webpackChunkName: "component---src-pages-payment-request-payment-request-js" */),
  "component---src-pages-view-receipt-index-js": () => import("./../../../src/pages/ViewReceipt/index.js" /* webpackChunkName: "component---src-pages-view-receipt-index-js" */),
  "component---src-pages-view-receipt-view-receipt-js": () => import("./../../../src/pages/ViewReceipt/ViewReceipt.js" /* webpackChunkName: "component---src-pages-view-receipt-view-receipt-js" */)
}

